<template>
  <div class="main-div">
    <div class="image-div">

    </div>
    <div class="login-div">
      <div class="text-center login-card">
        <img src="https://parabellum.si/wp-content/uploads/2023/02/logoW.png" class="logo"  alt="Parabellum"/>
        <v-text-field
            style="margin-top: 20px"
            outlined
            label="Uporabniško ime"
            v-model="userCredentials.username"
            dark
        >
        </v-text-field>
        <v-text-field
            style="margin-top: 20px"
            outlined
            label="Geslo"
            v-model="userCredentials.password"
            dark
            type="password"
        >
        </v-text-field>
        <div v-show="message !== ''">
          <v-icon color="yellow" large>mdi-alert</v-icon>
          <p style="color: #fcfc00; margin-top: 10px; margin-bottom: 30px">{{message}}</p>
        </div>
        <v-btn
            color="primary"
            style="width: 100%"
            @click="handleLogin()"
            :loading="loading"
            dark
        >
          Prijava
        </v-btn>

        <v-expand-transition>
          <v-snackbar
              v-model="snackbar"
              :timeout="4000"
              :color="snackbarColor"
              :elevation="24"
              multi-line
              right
              absolute
          >
            {{ message }}
          </v-snackbar>
        </v-expand-transition>
      </div>
    </div>




  </div>
</template>

<script>


export default {
  name: "login",
  title: "Prijava",
  data() {
    return {
      userCredentials: {},
      loading: false,
      warningBox: true,

      message: "",
      snackbar: false,
      snackbarColor: "#a82121",
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.userCredentials.username = "";
    this.userCredentials.password = "";

    if (this.currentUser !== null) {
      console.log("User logged in... Pushing to /memeber")
      this.$router.push("/member");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.userCredentials.username !== "" && this.userCredentials.password !== ""){
        this.$store.dispatch('auth/login', this.userCredentials).then(
            response => {
              localStorage.setItem('user', JSON.stringify(response.data));
              console.log("Pushing to /memeber")
              this.$router.push('/member');
            },
            error => {
              this.loading = false;
              console.log(error.response);
              if(error.response.data.message === "INVALID_CREDENTIALS"){
                this.message = "Nepravilno uporabniško ime ali geslo."
                this.snackbar = true;
                this.warningBox = true;
              }
              else if(error.response.data.message === "USER_DEACTIVATED"){
                this.message = "Deaktiviran uporabnik"
                this.snackbar = true;
                this.warningBox = true;
              }
              else {
                this.message =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();
                this.snackbar = true
              }
            }
        );
      }
      else {
        this.message = "Uporabniško ime in geslo sta obvezna!"
        this.snackbar = true
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

  .main-div {
    display: flex;
  }
  .image-div {
    flex: 2;
    background-color: black;
    height: 100vh;
    background-image: url("https://parabellum.si/wp-content/uploads/2023/02/sun-bullets-target.jpg");
  }
  .login-div {
    flex: 1;
    min-width: 500px;
    height: 100vh;
    background-image: linear-gradient(45deg, #7d7d7d, black);
  }
  .login-card {
    width: 450px;
    margin: auto;
    padding: 80px;
    margin-top: 20%;
    border: solid 1px gainsboro;
    border-radius: 30px;
    background-color: #424242;
  }
  .logo {
    width: 120px;
    margin-bottom: 30px;
  }


  @media only screen and (max-width: 1000px){
    .image-div{
      display: none;
    }
    .login-card {
      width: 450px;
      margin: auto;
      padding: 80px;
      margin-top: 30%;
      position: sticky;
      top: 0;
    }
    .login-div {
      width: 100%;
      min-width: unset;
      background-image: unset
    }
  }
  @media only screen and (max-width: 500px){
    .main-div {
      display: unset;
    }
    .login-card {
      width: 90%;
      justify-self: center;
      padding: 15px;
      padding-top: 80px;
      padding-bottom: 80px;
      margin-top: 30%
    }


  }
</style>
